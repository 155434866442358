exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about_us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility_statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-apointment-js": () => import("./../../../src/pages/Apointment.js" /* webpackChunkName: "component---src-pages-apointment-js" */),
  "component---src-pages-beauty-wellness-retreats-js": () => import("./../../../src/pages/beauty_wellness_retreats.js" /* webpackChunkName: "component---src-pages-beauty-wellness-retreats-js" */),
  "component---src-pages-blog-article-js": () => import("./../../../src/pages/blog_article.js" /* webpackChunkName: "component---src-pages-blog-article-js" */),
  "component---src-pages-blogarticle-js": () => import("./../../../src/pages/blogarticle.js" /* webpackChunkName: "component---src-pages-blogarticle-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-policy-js": () => import("./../../../src/pages/copyright_policy.js" /* webpackChunkName: "component---src-pages-copyright-policy-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-frequently-asked-questions-faq-js": () => import("./../../../src/pages/frequently_asked_questions_faq.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-home-contact-js": () => import("./../../../src/pages/home_contact.js" /* webpackChunkName: "component---src-pages-home-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mainphoto-js": () => import("./../../../src/pages/mainphoto.js" /* webpackChunkName: "component---src-pages-mainphoto-js" */),
  "component---src-pages-media-photo-gallery-js": () => import("./../../../src/pages/Media/photo_gallery.js" /* webpackChunkName: "component---src-pages-media-photo-gallery-js" */),
  "component---src-pages-media-youtube-gallery-js": () => import("./../../../src/pages/Media/youtube_gallery.js" /* webpackChunkName: "component---src-pages-media-youtube-gallery-js" */),
  "component---src-pages-medicine-js": () => import("./../../../src/pages/medicine.js" /* webpackChunkName: "component---src-pages-medicine-js" */),
  "component---src-pages-notice-js": () => import("./../../../src/pages/notice.js" /* webpackChunkName: "component---src-pages-notice-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rating-js": () => import("./../../../src/pages/rating.js" /* webpackChunkName: "component---src-pages-rating-js" */),
  "component---src-pages-rating-more-js": () => import("./../../../src/pages/rating_more.js" /* webpackChunkName: "component---src-pages-rating-more-js" */),
  "component---src-pages-rating-view-js": () => import("./../../../src/pages/rating_view.js" /* webpackChunkName: "component---src-pages-rating-view-js" */),
  "component---src-pages-rti-js": () => import("./../../../src/pages/rti.js" /* webpackChunkName: "component---src-pages-rti-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-speciality-cures-js": () => import("./../../../src/pages/speciality_cures.js" /* webpackChunkName: "component---src-pages-speciality-cures-js" */),
  "component---src-pages-team-all-doctor-js": () => import("./../../../src/pages/Team/all_doctor.js" /* webpackChunkName: "component---src-pages-team-all-doctor-js" */),
  "component---src-pages-team-all-staff-js": () => import("./../../../src/pages/Team/all_staff.js" /* webpackChunkName: "component---src-pages-team-all-staff-js" */),
  "component---src-pages-team-doctors-js": () => import("./../../../src/pages/Team/doctors.js" /* webpackChunkName: "component---src-pages-team-doctors-js" */),
  "component---src-pages-team-staffs-js": () => import("./../../../src/pages/Team/Staffs.js" /* webpackChunkName: "component---src-pages-team-staffs-js" */),
  "component---src-pages-term-condition-js": () => import("./../../../src/pages/term_&_condition.js" /* webpackChunkName: "component---src-pages-term-condition-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-treatment-js": () => import("./../../../src/pages/treatment.js" /* webpackChunkName: "component---src-pages-treatment-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-visit-a-doctor-js": () => import("./../../../src/pages/visit_a_doctor.js" /* webpackChunkName: "component---src-pages-visit-a-doctor-js" */),
  "component---src-pages-website-policy-js": () => import("./../../../src/pages/website_policy.js" /* webpackChunkName: "component---src-pages-website-policy-js" */),
  "component---src-templates-blog-article-detail-js": () => import("./../../../src/templates/blog_article_detail.js" /* webpackChunkName: "component---src-templates-blog-article-detail-js" */),
  "component---src-templates-notice-detail-js": () => import("./../../../src/templates/notice_detail.js" /* webpackChunkName: "component---src-templates-notice-detail-js" */),
  "component---src-templates-treatment-detail-js": () => import("./../../../src/templates/treatment_detail.js" /* webpackChunkName: "component---src-templates-treatment-detail-js" */)
}

